import React from 'react'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { Check } from 'react-feather'

const Offers = ({ lang }) => {
  const { t } = useTranslation()
  return (
    <>
      <div className="container">
        <div className="row mt-5">
          <div className="col-md-3 col-sm-12">
            <div className="offer-box">
              <div className="center">
                <img
                  className={'logo__offers'}
                  src={'/img/offer/starter.svg'}
                  alt={'Logo Quintyss'}
                />
                <h3>{t('page_offers_discovery')}</h3>
                <div className="list-advantages">
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right">{t('page_offers_Starter')}</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right">
                      10 pages Domaine gratuit pendant 1 an*
                    </div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> 2 Go d’espace disque</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> 2 Go de bande passante</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> 2 boîtes e-mail*</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> Propulsé par WebSelf</div>
                  </div>
                </div>
              </div>
              <div className="price">
                <span className="big-word">Gratuit</span>/mois
              </div>
              <Link className="btn-custom" to="/index">
                Commandez!
              </Link>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="offer-box inverse">
              <div className="center">
                <img
                  className={'logo__offers'}
                  src={'/img/offer/basic.svg'}
                  alt={'Logo Quintyss'}
                />
                <h3 className="inverse">Basic</h3>
                <div className="list-advantages inverse">
                  <div className="line-advantage">
                    <Check color="#fff" />
                    <div className="pad-right">Fonctionnalités de base</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#fff" />
                    <div className="pad-right">
                      10 pages Domaine gratuit pendant 1 an*
                    </div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#fff" />
                    <div className="pad-right"> 2 Go d’espace disque</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#fff" />
                    <div className="pad-right"> 2 Go de bande passante</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#fff" />
                    <div className="pad-right"> 2 boîtes e-mail*</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#fff" />
                    <div className="pad-right"> Propulsé par WebSelf</div>
                  </div>
                </div>
              </div>
              <div className="price inverse">
                <span className="big-word">7,90€</span>/mois
              </div>
              <Link className="btn-custom-inverse" to="/index">
                Commandez!
              </Link>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="offer-box">
              <div className="center">
                <img
                  className={'logo__offers'}
                  src={'/img/offer/premium.svg'}
                  alt={'Logo Quintyss'}
                />
                <h3>Premium</h3>
                <div className="list-advantages">
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right">Fonctionnalités de base</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right">
                      10 pages Domaine gratuit pendant 1 an*
                    </div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> 2 Go d’espace disque</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> 2 Go de bande passante</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> 2 boîtes e-mail*</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> Propulsé par WebSelf</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> Support premium</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> Multilingue</div>
                  </div>
                </div>
              </div>
              <div className="price">
                <span className="big-word">16,90€</span>/mois
              </div>
              <Link className="btn-custom" to="/index">
                Commandez!
              </Link>
            </div>
          </div>
          <div className="col-md-3 col-sm-12">
            <div className="offer-box">
              <div className="center">
                <img
                  className={'logo__offers'}
                  src={'/img/offer/ecommerce.svg'}
                  alt={'Logo Quintyss'}
                />
                <h3>E-commerce</h3>
                <div className="list-advantages">
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right">Fonctionnalités de base</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right">
                      10 pages Domaine gratuit pendant 1 an*
                    </div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> 2 Go d’espace disque</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> 2 Go de bande passante</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> 2 boîtes e-mail*</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> Propulsé par WebSelf</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> Boutique en ligne</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> Support premium</div>
                  </div>
                  <div className="line-advantage">
                    <Check color="#6f93f4" />
                    <div className="pad-right"> Multilingue</div>
                  </div>
                </div>
              </div>
              <div className="price">
                <span className="big-word">29,90€</span>/mois
              </div>
              <Link className="btn-custom" to="/index">
                Commandez!
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="box">
              <div className="line-start">
                <h3 className="details">Détails complet</h3>
                <div className="group-offers">
                  <div className="group-offer">Départ</div>
                  <div className="group-offer">Basic</div>
                  <div className="group-offer">Premium</div>
                  <div className="group-offer">E-commerce</div>
                </div>
              </div>
              <div className="line mt-3">
                <div className="hover" />
                <div className="label">Hébergement gratuit</div>
                <div className="group-offers">
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                </div>
              </div>
              <div className="line">
                <div className="label">Connexion sécurisée HTTPS</div>
                <div className="group-offers">
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                </div>
              </div>{' '}
              <div className="line">
                <div className="label">Bibliothèque d’images</div>
                <div className="group-offers">
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                </div>
              </div>
              <div className="line">
                <div className="label">Optimisé pour smartphones</div>
                <div className="group-offers">
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                </div>
              </div>
              <div className="line">
                <div className="label"> Apple Maps</div>
                <div className="group-offers">
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                </div>
              </div>
              <div className="line">
                <div className="label">Vidéos</div>
                <div className="group-offers">
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                </div>
              </div>
              <div className="line">
                <div className="label">Réseaux sociaux</div>
                <div className="group-offers">
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                </div>
              </div>
              <div className="line">
                <div className="label">
                  Optimisé pour le référencement (SEO)
                </div>
                <div className="group-offers">
                  <div className="group-offer">
                    <div className="none" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                  <div className="group-offer">
                    <Check color="#6f93f4" />
                  </div>
                </div>
              </div>
              <div className="line">
                <div className="label"></div>
                <div className="group-offers">
                  <div className="group-offer">
                    <Link className="btn-choice">Choisir</Link>
                  </div>
                  <div className="group-offer">
                    <Link className="btn-choice">Choisir</Link>
                  </div>
                  <div className="group-offer">
                    <Link className="btn-choice">Choisir</Link>
                  </div>
                  <div className="group-offer">
                    <Link className="btn-choice">Choisir</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Offers
