import React from 'react'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const Banner = ({ lang }) => {
  const { t } = useTranslation()
  return (
    <div className="banner">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="mt-7 mb-6 banner__title text-center mb-4">
              {t('page_offers_title')}
            </h1>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Banner
