import React from 'react'
import Layout from '../components/Layout'
import Header from '../components/Header'
import Banner from '../components/Offers/Banner'
import Offers from '../components/Offers'
import { graphql } from 'gatsby'

export default function Home({ pageContext }) {
  return (
    <Layout
      className="interne one-screen offers"
      locale={pageContext.language}
      template="offers"
    >
      <main>
        <Header black>
          <Banner />
        </Header>
        <Offers />
      </main>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
